import React from "react"
import RandomFlex from "../../Container/RandomFlex";
import DLsiteCard from "../../DLsite/Card";

const AIVOICECards = ({limit}) =>
{
  const adItems = 
  [
    //A.I.VOICE
    "VJ014432",//琴葉 茜・葵
    "VJ015678",//結月ゆかり
    "VJ015769",//紲星あかり

    "VJ015590",//GUMI
    "VJ01000421",//紡乃世詞音
    "VJ01000284",//若穂みのり
    "VJ015695",//咲ちゃん
    "VJ015696",//青山龍星
    "VJ015397",//RIA
    "VJ015642",//来果
    "VJ014433",//伊織弓鶴
    "VJ015180",//タンゲコトエ
    "VJ015051",//羽ノ華
    "VJ014815",//足立レイ
    "VJ015179",//式狼縁・式大元
    "VJ014992",//アナウンス部4話者セット
    "VJ01000530",//ZOLA Project
    "VJ01000595",//夜語トバリ
    "VJ01000707",//ユニティちゃん
    "VJ01000833",//VY Project
    "VJ01002082",//つくよみちゃん
    "VJ01002081",//琴葉 茜・葵（囁）
    "VJ01002155",//カキョウヨサリ
    "VJ01002516",//galaco（ギャラ子）
    "VJ01003440",//結月ゆかり 囁
  ]
  .map((item) => (<DLsiteCard store="soft" item={item}/>));
  return (<RandomFlex limit={limit}>{adItems}</RandomFlex>);
};

export default AIVOICECards

