import React from "react"
const Templates = 
[
  {
    isGeneral: true,
    date: "2025-03-21",
    name: "YMM4 テンプレート - BOOTH キーワード検索",
    url: "https://booth.pm/ja/search/YMM4%20%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
  },
  {
    date: "2025-03-21",
    name: "あおもや式YMM4テンプレート集",
    url: "https://ionian-gallimimus-e47.notion.site/YMM4-15a8c5bf8aa4802b90def49fd4b2a07d",
  },
  {
    date: "2025-03-21",
    name: "YMM4Teテンプレート一式",
    url: "https://note.com/tetra_te/n/n9942584a80b5",
  },
];

const TemplateList = ({max})=>
{  
  return Templates
    .slice(0, max)
    .filter((template,i)=>template.isEnabled != false)
    .sort((a,b)=>
    {
      const generalDiff = (b.isGeneral ? 1 : 0) - (a.isGeneral ? 1 : 0);
      if(generalDiff !== 0) return generalDiff;
      return Math.random() - 0.5;
    })
    .map((template,i)=>
    {
      return (
        <tr key={i}>
          <td>
            <a href={template.url} target="_blank" rel="noopener noreferrer">{template.name}</a>
          </td>
        </tr>)
    });
};

const YMM4Templates = ({max})=>{
  return (
    <table>
      <thead>
        <tr>
          <th>名前・配布元</th>
        </tr>
      </thead>
      <tbody>
        <TemplateList max={max}/>
      </tbody>
    </table>
  );
}

export default YMM4Templates;
